@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.logo-primary{
  fill:#eb2f96
}
.logo {
  width: 50px;
  height: 50px;
  float: left;
}
